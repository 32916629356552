import { useEffect, useState } from "react";
import * as React from "react";
import ReactSelect, { ActionMeta, OptionTypeBase } from "react-select";

import {
  MultiValue,
  multiValueStyle,
  MultiValueWithChips,
  multiValueWithChipsStyle,
} from "./elements/MultiValue";
import { MultiValueLabel } from "./elements/MultiValueLabel";
import { BaseSelectProps, DropdownIndicator } from "./Select";

const selectAllOption = {
  label: "Select All",
  value: "SELECT_ALL",
};

const isSelectAllOption = (
  option: OptionTypeBase | OptionTypeBase[] | null | undefined
): option is typeof selectAllOption => {
  return Array.isArray(option)
    ? option.find((o) => o.value === selectAllOption.value)
    : option?.value === selectAllOption.value;
};

type SelectOption = {
  label: string;
  value: string;
  options?: SelectOption[];
};

export const MultiSelect = ({
  multiSelectLabel,
  multiSelectWithChips,
  options: optionList = [],
  onChange: handleChange,
  defaultValue = [],
  components: propsComponents,
  styles,
  selectAll,
  value,
  ...props
}: BaseSelectProps) => {
  const components = multiSelectWithChips
    ? {
        MultiValueWithChips,
        MultiValueLabel,
        DropdownIndicator,
        ...propsComponents,
      }
    : {
        MultiValue,
        MultiValueLabel,
        DropdownIndicator,
        ...propsComponents,
      };
  // If a single defaultValue is passed, we coerce it into an array
  const defaultSelected = Array.isArray(defaultValue)
    ? defaultValue
    : [defaultValue];

  const [selected, setSelected] = useState<
    OptionTypeBase | OptionTypeBase[] | null
  >(defaultSelected);

  const [options, setOptions] = useState<OptionTypeBase[]>(optionList);

  const onChange = (
    option: OptionTypeBase | OptionTypeBase[] | null,
    inputAction: ActionMeta<OptionTypeBase> | null
  ) => {
    if (selectAll && isSelectAllOption(option)) {
      setSelected(optionList);

      if (handleChange && inputAction) handleChange(optionList, inputAction);

      return;
    }

    setSelected(option);
    if (handleChange && inputAction) handleChange(option, inputAction);
  };

  // reset selected options if value changes
  useEffect(() => {
    if (!value) return;

    const propValue = value || [];
    const selectedValue = selected || [];

    if (propValue.length !== selectedValue.length) {
      setSelected(propValue);
    }
  }, [value, selected]);

  useEffect(() => {
    const isNotSelected = (
      list: SelectOption[],
      selectedOptions: OptionTypeBase
    ) =>
      list.filter(
        (item: SelectOption) =>
          !selectedOptions.some(
            (option: OptionTypeBase) => item?.value === option?.value
          )
      );

    const groupSelectedOptions = (
      list: SelectOption[],
      selectedOptions: OptionTypeBase
    ) => {
      const selectedOptionsIncludingStrings = selectedOptions.map(
        (option: OptionTypeBase) => {
          if (
            typeof option === "string" &&
            list.find((obj: SelectOption) => obj?.value === option)
          ) {
            return list.find((obj: SelectOption) => obj?.value === option);
          }
          return option;
        }
      );
      if (
        !selectedOptionsIncludingStrings ||
        !selectedOptionsIncludingStrings.length
      )
        return setOptions(list);
      const unselectedOptions = isNotSelected(
        list,
        selectedOptionsIncludingStrings as SelectOption[]
      );

      const groupedOptions = [
        { label: "Selected", options: selectedOptionsIncludingStrings },
        { label: "Not Selected", options: unselectedOptions },
      ];

      const groupedNestedOptions = [...unselectedOptions];
      return setOptions(
        unselectedOptions[0]?.options ? groupedNestedOptions : groupedOptions
      );
    };

    groupSelectedOptions(
      optionList as SelectOption[],
      selected as OptionTypeBase
    );
  }, [selected, optionList]);

  const showSelectAllOption = selectAll && !selected?.length;

  return (
    <ReactSelect
      isMulti={true}
      hideSelectedOptions={false}
      multiSelectLabel={multiSelectLabel}
      closeMenuOnSelect={false}
      options={showSelectAllOption ? [selectAllOption, ...options] : options}
      components={components}
      onChange={onChange}
      value={selected}
      defaultValue={defaultValue}
      styles={{
        ...styles,
        multiValue: (base) => ({
          ...base,
          ...(multiSelectWithChips
            ? multiValueWithChipsStyle
            : multiValueStyle),
        }),
      }}
      {...props}
    />
  );
};
