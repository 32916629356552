import { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as React from "react";
import ReactSelect, { components, MultiValueProps } from "react-select";

import { Box, Flex } from "@plan/layout";

import { styled } from "../../../core";
import { IconCaretRight, IconSearch } from "../../../icons";
import { Badge } from "../Badge";
import { Input } from "../Input";
import { PopoverContent, PopoverRoot, PopoverTrigger } from "../Popover";

import type { BaseSelectProps } from "./Select";

type SelectOption = {
  label: string;
  value: string;
};

// FIXME: drop on first refactor
// eslint-disable-next-line
const MultiValue = (props: MultiValueProps<any>) => {
  if (props.hasValue) {
    return (
      <components.MultiValue {...props}>
        {props?.selectProps?.multiSelectLabel || "Selected"}
        <Badge intent="info" css={{ marginLeft: "$space$2" }}>
          {props.getValue().length}
        </Badge>
      </components.MultiValue>
    );
  }
  return <components.MultiValue {...props} />;
};

const MultiValueLabel = (props: { children: React.ReactElement }) => {
  return props.children;
};

const OptionWithSubMenu = styled(Box, {
  height: "36px",
  padding: "$2 $4",
  position: "relative",
  "&:hover": {
    background: "$-brand40",
  },
});

const SubMenuItem = styled(Box, {
  height: "36px",
  padding: "$2 $4",
  cursor: "pointer",
  "&:hover": {
    background: "$-brand40",
  },
  variants: {
    selected: {
      true: {
        background: "$colors$brand",
        color: "$white",
      },
    },
  },
});

const CustomOptionComp = ({ ...props }) => {
  const { onMenuInputFocus, setIsFocused } = props.selectProps;

  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = props.data.optionsData.filter((el: SelectOption) => {
    //if no input the return the original
    if (searchTerm.toLowerCase() === "") {
      return el;
    }
    //return the item which contains the user input
    else {
      return el.label.toLowerCase().includes(searchTerm);
    }
  });

  return (
    <OptionWithSubMenu
      onMouseDown={(e) => {
        e.stopPropagation();
        setIsFocused(true);
      }}
    >
      <PopoverRoot>
        <PopoverTrigger>
          <Flex
            className="sub-menu"
            css={{ justifyContent: "space-between", alignItems: "center" }}
          >
            {props.label} <IconCaretRight label="options" />
          </Flex>
        </PopoverTrigger>
        <PopoverContent
          side="right"
          sideOffset={25}
          align="start"
          css={{
            border: "1px solid $-neutral30",
            padding: "$2 0",
            "&:focus": {
              outline: "none",
            },
          }}
          onPointerDownOutside={(e) => {
            const { target } = e;
            if (
              target instanceof HTMLElement &&
              !target?.className.includes("sub-menu")
            ) {
              setIsFocused(false);
            }
          }}
        >
          <Box css={{ padding: "$1 $4" }}>
            {props.data.withSearchBar && (
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={props.data.searchBarPlaceholder || "Search"}
                type="text"
                withinField={true}
                value={searchTerm}
                prefix={<IconSearch label="find" />}
                onFocus={onMenuInputFocus}
                // For some reason, backspace doesnt work
                onKeyDown={(e) =>
                  e.key === "Backspace" &&
                  setSearchTerm(searchTerm.slice(0, -1))
                }
              />
            )}
          </Box>

          {filteredData.map((o: SelectOption) => (
            <SubMenuItem
              onClick={() => {
                if (props.data.type === "main") {
                  props.selectProps.onMainOptionSelect(o);
                } else {
                  props.selectProps.onSecondaryOptionSelect(o);
                }
              }}
              key={o.value}
              selected={
                props.data.value && props.data.value.length > 0
                  ? props.data.value.some(
                      (val: SelectOption) => val.value === o.value
                    )
                  : false
              }
            >
              {o.label}
            </SubMenuItem>
          ))}
        </PopoverContent>
      </PopoverRoot>
    </OptionWithSubMenu>
  );
};

type OptionsData = SelectOption & {
  mappingToMain?: string[];
};

type CustomOption = {
  custom: boolean;
  type: "main" | "secondary";
  label: string;
  value: SelectOption | SelectOption[] | null;
  optionsData: OptionsData[];
  setValue: Dispatch<SetStateAction<SelectOption | SelectOption[] | null | []>>;
  withSearchBar: boolean;
  searchBarPlaceholder: string;
};

type MultipSelectType = BaseSelectProps & {
  customOptions: CustomOption[];
};

export const NestedSelect = ({
  placeholder,
  multiSelectLabel,
  customOptions,
  components: propsComponents,
  styles,
  ...props
}: MultipSelectType) => {
  const components = {
    MultiValue,
    MultiValueLabel,
    MultiValueRemove: () => null,
    Option: CustomOptionComp,
    IndicatorSeparator: () => null,
    ...propsComponents,
  };

  const { className, classNamePrefix, id } = props;

  const {
    value: mainValue,
    setValue: setMainValue,
    optionsData: mainOptionsData,
  } = customOptions.find(
    (o: CustomOption) => o.type === "main"
  ) as CustomOption;

  const {
    value: secondaryValue,
    setValue: setSecondaryValue,
    optionsData: secondaryOptionsData,
  } = customOptions.find(
    (o: CustomOption) => o.type === "secondary"
  ) as CustomOption;

  const [isFocused, setIsFocused] = useState(false);

  const [val, setVal] = useState<SelectOption[] | undefined | null>(
    mainValue as SelectOption[]
  );

  const onSecondaryOptionSelect = (val: SelectOption) => {
    setSecondaryValue(val);
    setIsFocused(false);
    setVal(mainValue as SelectOption[]);
  };

  const onMainOptionSelect = (val: SelectOption) => {
    const isAlreadySelected =
      mainValue && (mainValue as SelectOption[]).length > 0
        ? (mainValue as SelectOption[]).some(
            (p: SelectOption) => p.value === val.value
          )
        : false;

    if (isAlreadySelected) {
      setSecondaryValue(null);
      const newmainValue = (mainValue as SelectOption[]).filter(
        (p: SelectOption) => p.value !== val.value
      );
      setMainValue(newmainValue);
    } else {
      const selected = mainOptionsData.find(
        (p: SelectOption) => p.value === val.value
      ) as SelectOption;
      setMainValue([...(mainValue as SelectOption[]), selected]);
    }
    setVal(mainValue as SelectOption[]);
  };

  useEffect(() => {
    if (secondaryValue) {
      const mainMapping =
        secondaryOptionsData.find(
          (p: SelectOption) =>
            p.value === (secondaryValue as SelectOption).value
        ) || [];
      // FIXME: drop on first refactor
      // eslint-disable-next-line
      // @ts-ignore
      const mainValue = mainMapping.mappingToMain.map((id: string) =>
        mainOptionsData.find((p: SelectOption) => p.value === id)
      );
      setMainValue(mainValue as SelectOption[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryValue]);

  return (
    <ReactSelect
      value={val}
      placeholder={placeholder}
      multiSelectLabel={multiSelectLabel}
      isClearable
      isMulti
      options={customOptions}
      components={components}
      onMenuInputFocus={() => setIsFocused(true)}
      {...{
        menuIsOpen: isFocused || undefined,
        isFocused: isFocused || undefined,
      }}
      setIsFocused={setIsFocused}
      onMainOptionSelect={onMainOptionSelect}
      onSecondaryOptionSelect={onSecondaryOptionSelect}
      mainValue={mainValue}
      styles={{
        ...styles,
        multiValue: (base) => ({
          ...base,
          // We're setting tag's background color to white
          backgroundColor: "white",
          // We're hiding all `MultiValue` components except the first one.
          "&:not(:first-child)": {
            display: "none",
          },
        }),
      }}
      onChange={(_, { action }) => {
        if (action === "clear")
          if (action === "clear") {
            setVal(undefined);
            setSecondaryValue(null);
            setMainValue([]);
          }
      }}
      className={className}
      id={id}
      classNamePrefix={classNamePrefix}
    />
  );
};
