
const digest = '7dc9b1b471af61a15228d53998f90cbd4a68033f0bad1704b3610e6c45e448d8';
const css = `._formWrapper_1kd6c_1 {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: repeat(auto-fill, 1fr);
}

._nameRow_1kd6c_7 {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"formWrapper":"_formWrapper_1kd6c_1","nameRow":"_nameRow_1kd6c_7"};
export { css, digest };
  