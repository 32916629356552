import { ComponentProps } from "react";
import ReactSelect, {
  components,
  MultiValueProps,
  OptionTypeBase,
} from "react-select";

import { colors } from "@plan/core";

import { Badge } from "../../Badge";

export const MultiValue = (props: MultiValueProps<OptionTypeBase>) => {
  if (props.hasValue) {
    return (
      <components.MultiValue {...props}>
        <>
          {!props?.selectProps?.menuIsOpen && (
            <>{props?.selectProps?.multiSelectLabel || "Selected"}</>
          )}
          <Badge intent="announcement" css={{ marginLeft: "$space$2" }}>
            {props.getValue().length}
          </Badge>
        </>
      </components.MultiValue>
    );
  }
  return <components.MultiValue {...props} />;
};

export const multiValueStyle = {
  // We're setting tag's background color to white
  backgroundColor: "white",
  // We're hiding all `MultiValue` components except the first one.
  "&:not(:first-child)": {
    display: "none",
  },

  "& .plan-select__multi-value__remove": {
    display: "none",
  },
};

export const MultiValueWithChips = () => {
  return <></>;
};

export const multiValueWithChipsStyle: ReturnType<
  NonNullable<
    NonNullable<ComponentProps<typeof ReactSelect>["styles"]>["multiValue"]
  >
> = {
  backgroundColor: colors["-neutral30"],
  padding: "0.35rem 0.5rem",
  borderRadius: "0.25rem",

  flexShrink: 0,

  color: colors["neutral40"],

  "& .plan-select__multi-value__remove": {
    color: colors["neutral10"],
    "&:hover": {
      cursor: "pointer",
      color: colors["neutral10"],
      backgroundColor: colors["-neutral30"],
    },
  },
};
