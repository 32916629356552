import applicationIndex from "Custom/application-index";
import invoicesEdit from "Custom/invoices-edit";
import invoicesIndex from "Custom/invoices-index";
import invoicesShow from "Custom/invoices-show";
import onboardingOrganization from "Custom/onboarding-organization";
import profilesWorkload from "Custom/profiles-workload";
import registrationsNew from "Custom/registrations-new";
import tasksIndex from "Custom/tasks-index";
import tasksShow from "Custom/tasks-show";

// This calls the appropriate javascript depending on the page and the turbolinks hook
// Turbolinks just calls something like controller.load() rather than using conditional statements.
const controller = {
  current: "",
  currentJs: null,
  load() {
    this.current = $("body")[0].dataset.js;
    this.currentJs = this.js[this.current];
    if (this.currentJs) {
      this.currentJs._load();
    }
  },
  beforeCache() {
    if (this.currentJs) {
      this.currentJs.beforeCache();
    }
  },
  js: {
    "application-index": applicationIndex,
    "invoices-edit": invoicesEdit,
    "invoices-index": invoicesIndex,
    "invoices-show": invoicesShow,
    "onboarding-organization": onboardingOrganization,
    "profiles-workload_profiles": profilesWorkload,
    "registrations-new": registrationsNew,
    "tasks-index": tasksIndex,
    "tasks-show": tasksShow,
  },
};

export default controller;
