import * as React from "react";

import { keyframes, styled } from "@plan/core";
import { Icon, IconDone, IconInfo, IconWarning } from "@plan/icons";
import { AutoLayout, Box } from "@plan/layout";

import { CloseButton } from "../CloseButton";
import { Text } from "../Text";

import * as ToastBase from "@radix-ui/react-toast";

type Intent = "none" | "warning" | "info" | "danger" | "success";

const defaultIcons = {
  info: <IconInfo label="Info" color="brand" />,
  warning: <IconWarning label="Warning" color="warning" />,
  danger: <IconWarning label="Danger" color="danger" />,
  success: <IconDone label="Success" color="success" />,
  none: <IconInfo label="Info" color="neutral" />,
};

export type ToastProps = {
  id?: string;
  /** intent: Which icon/color to show for the Toast */
  intent?: Intent;
  /** icon: What to display as the icon for the Toast. You can pass a React component, or `false` to hide the icon */
  icon?: React.ReactNode | false;
  /** message: Message to show on the Toast */
  message: React.ReactNode;
  /** close: Whether to display a close button on the Toast */
  close?: boolean;
  /** closeAfterMs: Number of ms to wait before the Toast should close itself */
  closeAfterMs?: number;
};

export type ToastsContextValue = {
  toasts: ToastProps[];
  addToast: (toast: ToastProps) => void;
  clearToasts: () => void;
  removeToast: (id: string) => void;
};

export const ToastsContext = React.createContext<ToastsContextValue>({
  addToast: () => null,
  clearToasts: () => null,
  removeToast: () => null,
  toasts: [],
});

export const useToasts = () => React.useContext(ToastsContext);

export const ToastsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  /** Store the Toasts */
  const [toasts, setToasts] = React.useState<ToastProps[]>([]);

  /** Add a new Toast */
  const addToast = (toast: ToastProps) => {
    toast.id = toast.id || new Date().getTime().toString(); // Set toast's ID automatically if none passed
    setToasts([toast, ...toasts]);
  };

  const handleClose = (id: string, isOpen: boolean) => {
    if (isOpen) return;
    const toastsWithout = toasts.filter((t) => t.id !== id);
    setToasts([...toastsWithout]);
  };

  const clearToasts = () => setToasts([]);

  const removeToast = (id: string) => {
    handleClose(id, false);
  };

  return (
    <ToastBase.Provider>
      <ToastsContext.Provider
        value={{ toasts, addToast, removeToast, clearToasts }}
      >
        {children}
        {toasts?.map((toast: ToastProps) => (
          <Box key={toast.id} css={{ marginBottom: "$space$4" }}>
            <Toast
              onOpenChange={(isOpen: boolean) =>
                handleClose(toast.id as string, isOpen)
              }
              id={toast.id}
              closeAfterMs={toast.closeAfterMs}
              message={toast.message}
              intent={toast.intent}
              close={toast.close}
            />
          </Box>
        ))}
      </ToastsContext.Provider>
    </ToastBase.Provider>
  );
};

const VIEWPORT_PADDING = 20;

const hide = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: "translateY(0)" },
});

const swipeOut = keyframes({
  from: { transform: "translateY(var(--radix-toast-swipe-end-x))" },
  to: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` },
});

const StyledToast = styled(ToastBase.Root, {
  "@media (prefers-reduced-motion: no-preference)": {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in forwards`,
    },
    '&[data-swipe="move"]': {
      transform: "translateX(var(--radix-toast-swipe-move-x))",
    },
    '&[data-swipe="cancel"]': {
      transform: "translateX(0)",
      transition: "transform 200ms ease-out",
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out forwards`,
    },
  },
});

const StyledViewport = styled(ToastBase.Viewport, {
  display: "flex",
  position: "fixed",
  flexDirection: "column-reverse",
  bottom: 0,
  zIndex: "$penthouse",
  left: `var(--sidebar)`,
  padding: VIEWPORT_PADDING,
});

export const Toasts = StyledViewport;

interface ToastsCallbackProps extends ToastProps {
  onOpenChange: (isOpen: boolean) => void;
}

export const Toast = ({
  message,
  icon: iconProp,
  intent = "none",
  closeAfterMs = 7000,
  id,
  close = true,
  onOpenChange,
}: ToastsCallbackProps) => {
  /**
   * prepareIcon
   * Set the icon correctly for the `intent`, or use what is passed to the `icon` prop
   *
   * @param _icon The contents of the icon= prop, so we can evaluate whether it's valid/false/etc
   * @param intent The intent of the Toast, so we can find the correct default icon if nothing is passed
   * @returns A ReactNode icon if one is passed, void if `false` is passed, or the default icon if nothing is passed
   */

  const prepareIcon = (_icon: React.ReactNode | false, intent: Intent) => {
    if (React.isValidElement(_icon)) return _icon; // Passing an icon component means "show that instead"
    if (_icon === false) return null; // `false` means don't show an icon
    return defaultIcons[intent]; // Fall back to the default icon
  };

  // Set up the icon
  const icon = prepareIcon(iconProp, intent);

  return (
    <StyledToast
      onOpenChange={onOpenChange}
      key={id}
      duration={closeAfterMs}
      asChild
    >
      <Box
        css={{
          borderRadius: "$radii$default",
          marginBottom: "$space$4",
          padding: "$space$2_5 $space$3",
          minHeight: "$sizes$xl",
          backgroundColor: "$white",
          boxShadow: "$shadow100",
          width: "$space$72",
          zIndex: 1,
        }}
      >
        <AutoLayout spacing="2">
          {icon && (
            <Box
              as="span"
              css={{
                display: "inline-flex",
                // for <Icon /> and <Spinner /> components
                [`svg:not(${Icon})`]: {
                  width: "$sizes$sm",
                  height: "$sizes$sm",
                },
                // for experimental individual <IconName /> components
                [`${Icon}`]: { fontSize: "$sizes$sm" },
              }}
            >
              {icon}
            </Box>
          )}
          <ToastBase.Description asChild>
            <Text css={{ flex: 1, paddingTop: "$space$0_5" }}>{message}</Text>
          </ToastBase.Description>
          {close && (
            <ToastBase.Close aria-label="Close" asChild>
              <CloseButton size="sm" />
            </ToastBase.Close>
          )}
        </AutoLayout>
      </Box>
    </StyledToast>
  );
};
