import * as React from "react";
import { ComponentProps, CSSProperties } from "react";

import { styled } from "@plan/core";
import { AutoLayout } from "@plan/layout";

import { useField } from "../Field";

import { useId } from "@radix-ui/react-id";
import * as LabelPrimitive from "@radix-ui/react-label";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

enum vars {
  borderRadius = "$$radioBorderRadius",
  indicatorColor = "$$radioIndicatorColor",
  indicatorSize = "$$radioIndicatorSize",
  itemBackgroundColor = "$$radioItemBackgroundColor",
  itemBorderColor = "$$radioItemBorderColor",
  itemBorderWidth = "$$radioItemBorderWidth",
  itemSize = "$$radioItemSize",
  labelColor = "$$radioLabelColor",
}

export type RadioGroupProps = Omit<
  RadioGroupPrimitive.RadioGroupProps,
  "asChild"
>;

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ children, ...props }, ref) => (
    <RadioGroupPrimitive.Root ref={ref} asChild {...props}>
      <AutoLayout direction="vertical" spacing="2">
        {children}
      </AutoLayout>
    </RadioGroupPrimitive.Root>
  )
);

const Container = styled("div", {
  [vars.borderRadius]: "$radii$round",
  [vars.indicatorColor]: "$colors$white",
  [vars.indicatorSize]: "$space$1_5",
  [vars.itemBackgroundColor]: "$colors$white",
  [vars.itemBorderColor]: "$colors$-neutral20",
  [vars.itemBorderWidth]: "$borderWidths$1",
  [vars.itemSize]: "$sizes$xs",
  [vars.labelColor]: "$colors$neutral40",
  display: "flex",
  alignItems: "center",
  textStyle: "large",
  "&:hover": {
    [vars.itemBorderColor]: "$colors$-brand30",
    [vars.itemBackgroundColor]: "$colors$-brand30",
  },
  "> * + *": {
    marginLeft: "$space$2",
  },
});

const Label = styled(LabelPrimitive.Root, {
  color: vars.labelColor,
  cursor: "default",
  userSelect: "none",
});

const Item = styled(RadioGroupPrimitive.Item, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: vars.itemBackgroundColor,
  width: vars.itemSize,
  minWidth: vars.itemSize,
  height: vars.itemSize,
  minHeight: vars.itemSize,
  borderColor: vars.itemBorderColor,
  borderStyle: "solid",
  borderWidth: vars.itemBorderWidth,
  borderRadius: vars.borderRadius,
  "&[disabled]": {
    [`&, & + ${Label}`]: {
      cursor: "not-allowed",
      [vars.itemBackgroundColor]: "$colors$-neutral30",
      [vars.itemBorderColor]: "$colors$-neutral30",
      [vars.labelColor]: "$colors$-neutral10",
    },
  },
  "&:not([disabled])": {
    "&:focus": {
      ring: "$colors$-brand30",
    },
    '&[data-state="checked"]': {
      [vars.itemBackgroundColor]: "$colors$brand",
      [vars.itemBorderColor]: "$colors$brand",
    },
  },
  variants: {
    invalid: {
      false: {},
      true: {
        '&:not([data-state="checked"])': {
          [vars.itemBorderColor]: "$colors$danger",
        },
      },
    },
  },
});

const Indicator = styled(RadioGroupPrimitive.Indicator, {
  display: "block",
  backgroundColor: vars.indicatorColor,
  borderRadius: vars.borderRadius,
  height: vars.indicatorSize,
  width: vars.indicatorSize,
});

export type RadioProps = Omit<
  RadioGroupPrimitive.RadioGroupItemProps,
  "asChild"
> & { css?: CSSProperties; labelProps?: ComponentProps<typeof Label> };

export const Radio: React.FC<RadioProps> = ({
  id: deterministicId,
  children,
  disabled,
  required,
  labelProps,
  ...props
}) => {
  const id = useId(deterministicId);

  const { getInputProps } = useField("fieldset");
  const inputProps = getInputProps({ disabled, required });

  return (
    <Container>
      <Item id={id} {...inputProps} {...props}>
        <Indicator />
      </Item>
      <Label htmlFor={id} {...labelProps}>
        {children}
      </Label>
    </Container>
  );
};
