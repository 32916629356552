import React, { useState, useEffect } from "react";
import { useAction, useForm, useValidation } from "Hooks";
import Modal from "Components/blocks/modal/Modal";
import Field from "Components/blocks/forms/Field";
import Form from "Components/blocks/forms/Form";
import { createProfile } from "Components/api/index";
import ActionButtons from "Components/blocks/buttons/ActionButtons";
import styles from "./add-team-member.module.css";

export function AddTeamMemberModal({ isOpen, actions, callback, policies }) {
  const [{ loading, data, error }, addProfile] = useAction(createProfile);
  const [policy, setPolicy] = useState(null);

  const handleSubmit = async ({ fname, lname, email }) => {
    const body = {
      fname,
      lname,
      email,
      policy_id: policy.value,
    };
    const profile = await addProfile(body);
    if (!profile) return;
    callback(profile);

    return actions.close();
  };

  const [values, onChange, onSubmit, setValues] = useForm(
    {
      fname: "",
      lname: "",
      email: "",
    },
    handleSubmit
  );

  const canSubmit = useValidation({
    ...values,
    policy: policy ? policy.value : null,
  });

  useEffect(() => {
    if (!isOpen) {
      setPolicy(null);
      setValues({
        fname: "",
        lname: "",
        email: "",
      });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      actions={actions}
      title="Invite Team Member"
      subHeading="to your organization"
      size="medium"
    >
      <Form onSubmit={onSubmit} error={error}>
        <div className={styles.formWrapper}>
          <div className={styles.nameRow}>
            <Field
              as="text"
              name="fname"
              label="First Name"
              value={values.fname}
              handleChange={onChange}
            />
            <Field
              as="text"
              name="lname"
              label="Last Name"
              value={values.lname}
              handleChange={onChange}
            />
          </div>
          <Field
            as="email"
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            handleChange={onChange}
          />
          <Field
            as="select"
            name="policy"
            label="Permission"
            placeholder="Select"
            options={policies}
            value={policy}
            handleChange={(e) => setPolicy(e)}
          />
          <ActionButtons
            buttons={[
              { buttonStyle: "cancel", text: "Cancel", action: actions.close },
              {
                buttonStyle: "action",
                buttonType: "submit",
                disabled: loading || !canSubmit,
                text: `${loading ? "Adding..." : "Add Team Member"}`,
                action: onSubmit,
              },
            ]}
          />
        </div>
      </Form>
    </Modal>
  );
}

export default React.memo(AddTeamMemberModal);
