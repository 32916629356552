
const digest = '4133d23a6e694eaf7133313f33f2e7bb5b44028c11deee2ffaf77471d2562f88';
const css = `._invoiceAssignmentSelector_q3edo_1._empty_q3edo_1 .select {
  border: 1px solid var(--color-red);
}

._tooltipContent_q3edo_5 {
  max-width: 10rem;
  color: var(--color-white);
  font-size: 0.8rem;
}

._selectedItem_q3edo_11 {
  font-weight: bold;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"invoiceAssignmentSelector":"_invoiceAssignmentSelector_q3edo_1","empty":"_empty_q3edo_1","tooltipContent":"_tooltipContent_q3edo_5","selectedItem":"_selectedItem_q3edo_11"};
export { css, digest };
  